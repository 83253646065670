const questions = [
  // 1 Слайд \\
  {
    questionText:
      "В офис пришел водитель. Просит повысить цены. Твои действия?",
    answerOptions: [
      {
        answerText:
          "Повышу, конечно. Пусть водители зарабатывают, мы же коллеги",
        answerResult:
          "Понимаем. Но люди станут реже ездить, и водители не заработают больше. Франчайзи «Максим» знают о ценообразовании все — они проходят бесплатное обучение.",
        result: false,
      },
      {
        answerText:
          "Объясню, что цены создают баланс между заработком водителя и доступностью услуги для клиента",
        answerResult:
          "Молодец! Направление мысли верное, хотя это работает немного сложнее. Франчайзи «Максим» знают о ценообразовании все — они проходят бесплатное обучение",
        result: true,
      },
      {
        answerText:
          "Я занят, бизнесом управляю. Пусть подождет, когда освобожусь, там и поговорим",
        answerResult:
          "Водители — твои партнеры, и относиться к ним нужно соответствующе. Если нет времени, поговорить с водителем может менеджер",
        result: false,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд
  {
    questionText:
      "Ох, клиент пожаловался на долгое ожидание. Заказов много, нужно больше водителей. Как будешь привлекать?",
    answerOptions: [
      {
        answerText:
          "Я же знаю, где в моем городе водители собираются. Просто приду туда",
        answerResult:
          "Почему бы и нет, может сработать. А еще эффективнее совместить с размещением рекламы. «Максим» дарит франчайзи рекламный пакет на 50 000 ₽",
        result: false,
      },
      {
        answerText:
          "У меня много друзей-таксистов. Попрошу их рассказать о сервисе знакомым",
        answerResult:
          "Сарафанное радио — рабочий вариант. Еще больше водителей привлечешь, если разместишь рекламу. «Максим» дарит франчайзи набор готовых материалов на 50 000 ₽",
        result: false,
      },
      {
        answerText: "Запущу рекламу, конечно. Это эффективнее всего",
        answerResult:
          "И денег много не потратишь: франчайзи сервиса «Максим» получают в подарок рекламный пакет на 50 000 ₽. Материалы останется только разместить",
        result: true,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд
  {
    questionText:
      "Клиент вез закрутки от любимой бабушки и забыл банки в багажнике. Жутко расстроился. Что будем делать?",
    answerOptions: [
      {
        answerText:
          "Менеджеры в офисе разберутся. Денег на зарплаты много уходит, конечно, но нельзя оставлять обращения без реакции",
        answerResult:
          "Знаем способ сэкономить на обработке отзывов: стать франчайзи «Максим». С вопросами от клиентов помогут специалисты удаленного офиса",
        result: true,
      },
      {
        answerText:
          "Сам свяжусь с человеком, когда буду посвободнее. Разберемся, гостинцы от бабули — это святое",
        answerResult:
          "Спасибо! 💛 Но тебя на все случаи не хватит, лучше делегировать. У франчайзи «Максима» на обращения клиентов отвечают специалисты удаленного офиса",
        result: false,
      },
      {
        answerText:
          "Забыл и забыл. Обойдется без закруток. Лучше надо следить за своими вещами",
        answerResult:
          "Люди будут постоянно обращаться в сервис, и всем надо ответить, иначе клиенты уйдут. «Максим» подключает франчайзи к удаленному офису, общение с пользователями специалисты берут на себя",
        result: false,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд
  {
    questionText:
      "У конкурентов упали сервера, и приложение перестало принимать заказы. Как избежать такой ситуации у себя?",
    answerOptions: [
      {
        answerText:
          "Никак. Все приложения падают время от времени, особенно если нагрузка большая",
        answerResult:
          "Вероятность сбоя минимизирует техподдержка. У франчайзи «Максим» работу приложения контролируют круглые сутки",
        result: false,
      },
      {
        answerText: "Это что, еще и айтишника нанимать придется?!",
        answerResult:
          "Не придется, если ты франчайзи сервиса «Максим»: они пользуются бесплатной круглосуточной техподдержкой",
        result: false,
      },
      {
        answerText:
          "Я все предусмотрел: разработчик приложения обеспечивает техподдержку",
        answerResult:
          "Молодец! «Максим» предоставляет франчайзи такие же условия: бесплатная техподдержка 24/7 ",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд
  {
    questionText:
      "Вот и месяц закончился. Выручка хорошая, но это не предел. Как понять, в какую сторону двигаться?",
    answerOptions: [
      {
        answerText: "Да какая разница? Просто постараюсь работать еще лучше",
        answerResult:
          "А как понять, стало лучше или хуже? Нужна статистика. Франчайзи сервиса «Максим» получают софт для руководителя и генерируют любые отчеты за пару минут",
        result: false,
      },
      {
        answerText: "Изучу «Эксель», составлю отчет и сравню показатели",
        answerResult:
          "Знаем, как сэкономить кучу времени. Франчайзи сервиса «Максим» пользуются софтом для руководителя, отчет за любой период генерируется автоматически",
        result: true,
      },
      {
        answerText:
          "Все очевидно: надо мотивировать водителей, чтобы больше работали",
        answerResult:
          "Или, может, привлечь еще клиентов? Нужна статистика. Франчайзи сервиса «Максим» получают программный комплекс для руководителя — и любой отчет генерируется за пару минут",
        result: false,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
